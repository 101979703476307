<template>
    <div class="content">
        <div class="search_box">
            <Input v-model="storePrams.keyword" style="width:200px" placeholder="请输入姓名/身份证"></Input>
            <Button @click="searchBtn" style="color:#FFF;background:#34B8FF;margin-left:10px">搜索</Button>
        </div>
        <Table :columns="columns12" :data="data6">
            <template slot-scope="{ index }" slot="number">
                <div>{{index+1}}</div>
            </template>
            <template slot-scope="{ row }" slot="sex">
                <div v-if="row.sex==1">男</div>
                <div v-if="row.sex==2">女</div>
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button @click="addBtn(row.id)" type="primary" ghost size="small" style="margin-right: 10px;color:#34B8FF;border-color:#34B8FF">开单</Button>
                <Button @click="lookBtn(row.id)" type="success" ghost size="small">查看</Button>
            </template>
        </Table>
        <br/>
        <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
        
        <!-- 开单弹框 -->
        <Modal @on-cancel="cancel" v-model="billModal">
            <p slot="header" style="font-weight:600;font-size:18px">{{title}}</p>
            <Form style="padding:0 30px" label-position="left" :label-width="100">
                <FormItem label="接种时间">
                    <DatePicker v-model="formData.handle_time" type="date" placeholder="点击选择日期" style="width: 100%"></DatePicker>
                </FormItem>
                <FormItem label="接种记录">
                    <Input v-model="formData.content" placeholder="药品名称"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="billBtn" size="large" style="background:#38B6FB;color:#FFF;border-radius:10px">确认</Button>
            </div>
        </Modal>
        
        <!-- 查看弹框 -->
        <Modal width="600" v-model="seeModal">
            <p slot="header" style="font-weight:600;font-size:18px">接种记录</p>
            <div class="inoculation_box">
                <div class="table_nav">
                    <div class="wep50">接种时间</div>
                    <div class="wep50">接种记录</div>
                    <div class="wep50">操作</div>
                </div>
                <div v-if="inoculationlist.length!=0">
                    <div class="item_th" :style="index % 2 == 1?'background:#EEF9FF':''" v-for="(item,index) of inoculationlist" :key="index">
                        <div class="wep50">{{item.handle_time}}</div>
                        <div class="wep50">{{item.content}}</div>
                        <div class="wep50">
                            <Button @click="editBtn(item)" type="primary" ghost style="margin-right:5px" size="small">修改</Button>
                            <Button @click="delBtn(item.id)" type="error" ghost size="small">删除</Button>
                        </div>
                    </div>
                </div>
                <div style="text-align:center;margin:20px 0 0;color:#999" v-else>
                    暂无记录~
                </div>
                <br/>
                <Page :total="total1" :current="storePrams1.page" style="text-align:right" show-elevator show-total @on-change="pageChange1" :page-size="storePrams1.limit"/>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
    import { userList,bill,getBillList,billDel,updateBill } from "@/api/index.js";
    import moment from "moment";
    export default {
        data(){
            return{
                total:0,
                storePrams:{
                    page:1,
                    limit:10,
                    keyword:'',
                },
                title:'',
                total1:0,
                storePrams1:{
                    page:1,
                    limit:8,
                    id:null,
                },
                columns12: [
                    {
                        title: '序号',
                        slot: 'number',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '姓名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '性别',
                        slot: 'sex',
                        align: 'center'
                    },
                    {
                        title: '年龄',
                        key: 'age',
                        align: 'center'
                    },
                    {
                        title: '身份证',
                        key: 'identity_card',
                        align: 'center'
                    },
                    {
                        title: '地址',
                        key: 'address',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 150,
                        align: 'center'
                    }
                ],
                data6: [],
                formData:{
                    id:null,
                    handle_time:'',
                    content:''
                },
                seeModal:false,
                billModal:false,
                inoculationlist:[],
            }
        },
        created(){
            this.userList()
        },
        methods:{
            userList(){
                userList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data6 = res.data.data
                })
            },
            getBillList(){
                getBillList(this.storePrams1).then(res=>{
                    this.total1 = res.data.total
                    this.inoculationlist = res.data.data
                })
            },
            delBtn(id){
                this.$Modal.confirm({
                    title: '提示',
                    content: `您正在使用删除功能`,
                    onOk: () => {
                        billDel({id:id}).then(res=>{
                            this.$Message.success(res.msg)
                            this.getBillList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                    onCancel: () => {}
                });
            },
            editBtn(e){
                this.title = '编辑'
                this.formData.id = e.id
                this.formData.handle_time = e.handle_time
                this.formData.content = e.content
                this.billModal = true
            },
            searchBtn(){
                this.userList()
            },
            lookBtn(e){
                this.storePrams1.id = e
                this.getBillList()
                this.seeModal = true
            },
            addBtn(id){
                this.title = '开单'
                this.formData.id = id
                this.billModal = true
            },
            billBtn(){
                if(this.formData.handle_time){
                    this.formData.handle_time = moment(this.formData.handle_time).format('YYYY-MM-DD')
                }
                if(this.title=='开单'){
                    bill(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.cancel()
                        this.billModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else{
                    updateBill(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.cancel()
                        this.getBillList()
                        this.billModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }
            },
            cancel(){
                this.formData = {
                    id:null,
                    handle_time:'',
                    content:''
                }
            },
            pageChange(e){
                this.storePrams.page = e
                this.userList()
            },
            pageChange1(e){
                this.storePrams1.page = e
                this.getBillList()
            }
        }
    }
</script>

<style scoped>
    .content{
        width: 100%;
        height: calc(100vh - 104px);
        overflow: auto;
        padding: 20px 40px;
        background: #FFF;
        border-radius: 8px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .search_box{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .inoculation_box{
        /* max-height: 500px;
        overflow: auto; */
    }
    .table_nav{
        background: #D6F1FF;
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 16px;
        color: #445E87;
    }
    .item_th{
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 16px;
        color: #445E87;
    }
    .wep50{
        width: 33.33%;
        text-align: center;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
</style>
<style>
    .ivu-table-header thead tr th{
        background: #AEE3FF;
    }
    .ivu-table td, .ivu-table th {
        border-bottom: 1px solid #F0F6FC;
        /* color: #74798C; */
    }
    .ivu-table:before{
        background: #F0F6FC;
    }
    .ivu-modal-footer , .ivu-modal-header{
        border: none;
    }
    .ivu-form .ivu-form-item-label{
        color: #445E87;
        font-size: 16px;
    }
    .ivu-input{
        border-radius: 10px;
    }
</style>